<app-header styleclass="podcast"></app-header>
<h1>¡Nuestro Libro!</h1>
<div class="content">
    <div class="parrafo">
<pre>Será una recolección
de testimonios y reflexiones 
recogidas de nuestros Podcast
con tus opiniones, preguntas
críticas y propuestas.
Saldrá en 2022.
        </pre>
        <p>Saldrá en 2022. Será una recolección
            de testimonios y reflexiones recogidos
            de nuestros Podcast
            y con tus opiniones, preguntas
            críticas y propuestas.
                    </p>
    </div>
    <div class="buttons">
    <app-button class="button" clasStyle="buttonlibro" text="Preordenar"></app-button>
    <label class="">¡Próximamente!</label>
    </div>
    <img class="libro" src="assets/Libro.svg" alt="">
</div>
<div>
    <app-footer ></app-footer>
</div>