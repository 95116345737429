<!DOCTYPE html>
<html lang="en-us">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <title>Unity WebGL Player | MuyMachitoGame</title>
  </head>
  <body>
    <div class="webgl-content">
      <div id="unityContainer" style="width: 100vw; height: 100vh;
    cursor: default;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;"></div>
    </div>
  </body>
</html>

<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
<meta name="viewport" content="viewport-fit=cover, user-scalable=yes, width=device-width, initial-scale=1, maximum-scale=1">