<app-header styleclass="podcast"></app-header>
<!-- styleclass="podcast" -->
<div class="content-title">
    <h1>PODCAST</h1>
    <div>
        <img class="" src="assets/iconitunes.svg" alt="">
        <img class="deezer" src="assets/dezzer.svg" alt="">
        <img src="assets/spotify.svg" alt="">
    </div>
</div>

<div class="content-podcast">
    <div class="podcast">
        <button (click)="modalAlert('https://youtu.be/zbaOfO_u_Ss','https://open.spotify.com/episode/16mcib2YH8PwGhQpbkP6WQ?si=r9sniOdeSM-t2z6gnAWgkg&utm_source=copy-link','https://deezer.page.link/xWq2C6MfjPeWippv6','https://music.amazon.com/podcasts/b859648a-1c3a-477c-8ddd-f30601949b10/%C2%BFmuy-machito?ref=dm_sh_wgOKXHXBe1NhploXb9co3eack','https://podcasts.apple.com/co/podcast/muy-machito/id1593687000?l=en')">
        <img src="assets/OnePodcast.jpg">
        </button>
    </div>
    <div class="podcast">
        <button (click)="modalAlert('https://youtu.be/_9AAKeQurVc','https://open.spotify.com/episode/4GF0y70R8zuLi8XDSaZi41?si=adb2968d671d4054','https://deezer.page.link/4WQk7QD9CE6eVDUX7','https://music.amazon.com/podcasts/b859648a-1c3a-477c-8ddd-f30601949b10/episodes/a534da51-1513-4b47-b1dd-b2f35043900f/%C2%BFmuy-machito-el-dise%C3%B1ador-de-ropa-de-una-barbie-empoderada---%C2%BFmuy-machito','https://podcasts.apple.com/co/podcast/el-dise%C3%B1ador-de-ropa-de-una-barbie-empoderada-muy-machito/id1593687000?i=1000542903179')">
            <img src="assets/TwoPodcast.jpeg">
            </button>
    </div>
    <div class="podcast">
        <button>
            <img src="assets/SoonPodcast.jpeg">
            </button>
    </div>
    <div class="podcast">
        <button>
            <img src="assets/SoonPodcast.jpeg">
            </button>
    </div>
    <div class="podcast">
        <button>
            <img src="assets/SoonPodcast.jpeg">
            </button>
    </div>
    <div class="podcast">
        <button>
            <img src="assets/SoonPodcast.jpeg">
            </button>
    </div>
</div>

<app-footer ></app-footer>