<header>
  <div [class]="styleclass">
  <img class="logo" src="assets/logoMachito.png">
  <input type="checkbox" id="btn-menu">
  <label for="btn-menu" class="icon"><img src="assets/MenuIcon.png"></label>
  <nav>
    <li *ngFor="let item of menu"> <a [href]="item.ruta">{{item.texto}}</a></li>
  </nav>
  </div>
</header>


